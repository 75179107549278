import TranslateIcon from '@mui/icons-material/Translate';
import MapIcon from '@mui/icons-material/Map';

export function getIcon(type) {
    switch (type) {
        case 'continent':
            return <MapIcon fontSize={"small"}/>
        case 'language':
            return <TranslateIcon fontSize={"small"}/>
        default:
            return null
    }
}