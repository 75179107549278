import React from 'react';
import {Stack} from "@mui/material";
import Header from "./components/header";
import Chart from "./components/chart";
import "./style.css"

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: "#fff",
        overflow: "auto",
        borderRadius: "8px",
        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)",
        border: "1px solid #e2e8f0",
    }
};

const FrequencyChart = () => {
    return (
        <Stack>
            <Header/>
            <Chart/>
        </Stack>
    )
};

export default FrequencyChart;