import React, {useEffect, useState} from 'react';
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import {useSearchParams} from "react-router-dom";
import {firestore, functions} from "../../../../config";
import {Box} from "@mui/material";
import SmallChart from "../../../../components/small_chart";

const createIncrementTask = functions.httpsCallable("createIncrementTask");

const Table = ({props}) => {
    const {path, name, type} = props;
    const {RTDBpath, docPath} = path;

    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true)

    let [searchParams, setSearchParams] = useSearchParams();
    const range = Number(searchParams.get('r'));
    const interval = searchParams.get('i');

    useEffect(() => {
        setData(null);
        setIsLoading(true);

        const docRef = firestore.doc(docPath);

        return docRef.onSnapshot((doc) => {
            console.log('DataTable onSnapshot');
            setData(null);
            if (doc.exists) {
                const indexes = searchParams.get('z').split('-').map(Number);
                const docData = doc.data();
                const newData = getZoomedData(docData, indexes);
                setData(newData);
                setIsLoading(false);
            } else {
                console.log('Document does not exist');
            }
            createIncrementTask(RTDBpath)
        });
    }, [path, range, interval, name]);

    useEffect(() => {
        //on zoomedRange.indexes change update total
        const indexes = searchParams.get('z').split('-').map(e => Number(e));
        const new_data = getZoomedData(data, indexes)
        setData(new_data);
    }, [searchParams]);

    const columns: GridColDef<(typeof rows)[number]>[] = [{
        field: 'data', headerName: 'Data', flex: 1,
    }, {
        field: 'chart', headerName: 'Frequency over time', flex: 2, renderCell: (params) => {
            const {published, count} = params.row;
            return (<Box py={0}>
                <SmallChart series={{published, count, max: data.max}}/>
            </Box>)
        }, py: 2
    }, {
        field: 'total', headerName: 'Total', type: 'number',
    }];

    return data && <DataGrid
        columns={columns}
        rows={data ? data.items.filter(item => item.data !== null && item.data !== '' && item.data !== undefined) : []}
        getRowId={(row) => row.data ? row.data : 'und'}
        initialState={{
            pagination: {
                paginationModel: {
                    pageSize: 10,
                },
            },
        }}
        rowHeight={48}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        loading={isLoading}
        onRowClick={(params, event) => {
            // params contains information about the row
            // event is the click event
            console.log(params.row); // log the row data to the console
            handleClick({type: type ? type : name, value: params.row.data}, searchParams, setSearchParams)
        }}
        sx={{
            // disable cell selection style
            '.MuiDataGrid-cell:focus': {
                outline: 'none'
            }, // pointer cursor on ALL rows
            '& .MuiDataGrid-row:hover': {
                cursor: 'pointer'
            }
        }}
    />
};

export default Table;


function handleClick(crumb, searchParams, setSearchParams) {
    const breadcrumbs = JSON.parse(searchParams.get('b')) || [];
    const new_breadcrumbs = breadcrumbs?.concat(crumb);
    searchParams.set("b", JSON.stringify(new_breadcrumbs))
    setSearchParams(searchParams)
}

function getZoomedData(all_data, indexes) {
    if (all_data && all_data.items) {
        let new_items = all_data.items.map((item, index) => {
            const new_total = item.count.slice(indexes[0], indexes[1] + 1).reduce((a, b) => a + b, 0);
            return {...item, total: new_total}
        });

        new_items.sort((a, b) => b.total - a.total);
        return {...all_data, items: new_items}
    }
}