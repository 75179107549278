import React, {useEffect, useState} from 'react';
import {Stack} from "@mui/material";
import ReactECharts from "echarts-for-react";
import {useSearchParams} from "react-router-dom";


let options = {
    grid: {top: 0, right: 0, left: 0, bottom: 0, height: 48, width: "100%"},
    animation: false,
    // animationDuration: 300,
    xAxis: {
        type: 'category',
        data: [],
        show: true,
        boundaryGap: false,
        axisTick: {
            alignWithLabel: true
        },
    },
    yAxis: {
        type: 'value',
        show: false,
    },
    series: [
        {
            data: [],
            type: 'line',
            symbol: 'none',
            areaStyle: {},
        },
    ]
};


const SmallChart = ({series}) => {
    const [chartRef, setChartRef] = useState(null)
    let [searchParams, setSearchParams] = useSearchParams();
    const indexes = searchParams.get('z').split('-').map(e => Number(e))

    useEffect(() => {
        if (chartRef) {
            const {published, count, max} = series
            // Only update state if the component is still mounted
            const options = chartRef.getEchartsInstance().getOption();
            // setChartOptions({
            chartRef.getEchartsInstance().setOption({
                ...options,
                xAxis: {data: published},
                yAxis: {max: max},
                series: [{data: count}],
                visualMap: {
                    show: false,
                    dimension: 0,
                    pieces: [
                        {
                            lte: indexes[0],
                            color: 'rgba(0, 0, 0, 0.1)'
                        },
                        {
                            gt: indexes[0],
                            lte: indexes[1],
                            color: 'blue'
                        },
                        {
                            gt: indexes[1],
                            color: 'rgba(0, 0, 0, 0.1)'
                        }
                    ]
                }
            })

        } else {
            console.log('no ChartRef in a Small_Chart');
        }
    }, [chartRef, series, indexes]);


    return (
        <Stack>
            <ReactECharts
                option={options}
                style={{height: 48}}
                ref={(e) => {
                    e && setChartRef(e);
                }}
            />
        </Stack>
    );
};

export default SmallChart;