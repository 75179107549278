import React, {useEffect, useState} from 'react';
import {Button, IconButton, InputAdornment, OutlinedInput, Stack, TextField} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {useSearchParams} from "react-router-dom";

const SearchInput = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [value, setValue] = useState(searchParams.get("k") || '');
    const size = "small"
    let keywords = searchParams.get("k");

    useEffect(() => {
        setValue(keywords || '')
    }, [keywords]);


    return <Stack direction={"row"} spacing={1} alignItems={"center"}>
        {/*<Typography variant={"h4"} fontWeight={700}>Misha is my son!</Typography>*/}
        <OutlinedInput
            id="outlined-basic"
            placeholder="Keywords search: banana, plátano, банан, 香蕉, בננה, موز"
            variant="outlined"
            value={value}
            fullWidth
            autoComplete="off" // Disable autofill
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    handleClick(value)
                }
            }}
            size={size}
            endAdornment={
                value.length > 0 &&
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleClear}
                        edge="end">
                        <ClearIcon/>
                    </IconButton>
                </InputAdornment>
            }
        />
        <Button onClick={() => handleClick(value)}>Search</Button>
    </Stack>;

    function handleClick(value) {
        searchParams.set("k", value);
        setSearchParams(searchParams);
    }

    function handleClear() {
        setValue('')
        searchParams.delete("k");
        setSearchParams(searchParams);
    }
};

export default SearchInput;