import React from 'react';
import {Provider} from "react-redux";
import store from "../../store";
import Exploration from "./exploration";

const ExplorationWrapper = () => {
    return (
        <Provider store={store}>
            <Exploration/>
        </Provider>
    );
};

export default ExplorationWrapper;