import {createSlice} from '@reduxjs/toolkit';

let searchParams = new URLSearchParams(window.location.search);
let range = Number(searchParams.get('r'));
if (!range || (range !== 180 && range !== 30 && range !== 7)) {
    range = 7;
    setURLSearchParams('r', range);
}

let interval = searchParams.get('i');
if (!interval || (interval !== 'daily' && interval !== 'hourly')) {
    interval = 'daily';
    setURLSearchParams('i', interval);
}

let breadcrumbs = searchParams.get('b');
if (breadcrumbs) {

}

const zoomedRange = searchParams.get('z');
if (!zoomedRange) {
    setURLSearchParams("z", `${0}-${(interval === 'daily' ? range : 24 * range) - 1}`)
}


export const explorationSlice = createSlice({
    name: 'exploration',
    initialState: {
        cacheKey: null,
        query: {
            continent: [],
            region: [],
            country: [],
            language: [],
            source: [],
            category: [],
            sentiment: [],
            entity_class: [],
            entity: [],
        },
        filters: {
            continent: [],
            region: [],
            country: [],
            language: [],
            source: [],
            category: [],
            sentiment: [],
            entity_class: [],
            entity: [],
        },
        // breadcrumbs: breadcrumbs,
        // breadcrumbs: [{type: 'language', value: 'de'}],
        total: null,
        dates: null,

        // zoomedRange: {
        //     indexes: {
        //         start: 0,
        //         end: interval === 'daily' ? range : 24 * range,
        //     },
        //     dates: {
        //         start: epochToDateTimeString(Date.now() - (range - 1) * 86400000),
        //         end: epochToDateTimeString(Date.now() + 86400000)
        //     },
        //     percents: {
        //         start: 0,
        //         end: 100,
        //     }
        // },
    },
    reducers: {
        setCacheKey: (state, action) => {
            console.log("dispatch CACHEKEY")
            state.cacheKey = action.payload;
        },
        setRange: (state, action) => {
            console.log("dispatch")
            setURLSearchParams('r', action.payload);
            state.zoomedRange = {
                indexes: {
                    start: 0,
                    end: state.interval === 'daily' ? action.payload : 24 * action.payload,
                },
                dates: {
                    start: epochToDateTimeString(Date.now() - (state.range - 1) * 86400000),
                    end: epochToDateTimeString(Date.now() + 86400000)
                },
                percents: {
                    start: 0,
                    end: 100,
                }
            };
            state.range = action.payload;
        },
        setInterval: (state, action) => {
            console.log("dispatch")
            setURLSearchParams('i', action.payload);
            state.zoomedRange = {
                indexes: {
                    start: 0,
                    end: action.payload === 'daily' ? state.range : 24 * state.range,
                },
                dates: {
                    start: epochToDateTimeString(Date.now() - (state.range - 1) * 86400000),
                    end: epochToDateTimeString(Date.now() + 86400000)
                },
                percents: {
                    start: 0,
                    end: 100,
                }
            };
            state.interval = action.payload;
        },
        setTotal: (state, action) => {
            console.log("dispatch TOTAL")
            state.total = action.payload;
        },
        setQuery: (state, action) => {
            console.log("dispatch")
            const {
                query,
                entity,
                language,
                country,
                range
            } = action.payload;

            if (query) state.query = query;

            if (entity) state.query.entities = entity;
            if (country) state.query.countries = country;
            if (language) state.query.language = language;
            if (range) state.query.range = range;
        },
        addBreadcrumb: (state, action) => {
            console.log("dispatch")
            const crumb = action.payload;
            state.breadcrumbs = state.breadcrumbs?.concat(crumb);
        },
        removeBreadcrumb: (state, action) => {
            console.log("dispatch")
            const {type, value} = action.payload;
            const index = state.breadcrumbs.findIndex(e => e.type === type && e.value === value);
            state.breadcrumbs = state.breadcrumbs?.filter((e, i) => i !== index);
        },
        sliceBreadcrumb: (state, action) => {
            console.log("dispatch")
            const {type, value} = action.payload;
            const index = state.breadcrumbs.findIndex(e => e.type === type && e.value === value);
            state.breadcrumbs = state.breadcrumbs?.slice(0, index + 1);
        },
        resetBreadcrumbs: (state, action) => {
            console.log("dispatch")
            state.breadcrumbs = [];
        },
        setZoomedRange: (state, action) => {
            console.log("dispatch")
            const {payload} = action;
            state.zoomedRange = payload;
        },
        setDates: (state, action) => {
            console.log("dispatch DATES")
            const {payload} = action;
            state.dates = payload;
        },
        resetZoomIndexes: (state) => {
            console.log("dispatch")
            state.zoomedRange = {
                indexes: {
                    start: 0,
                    end: state.interval === 'daily' ? state.range : 24 * state.range,
                },
                dates: {
                    start: epochToDateTimeString(Date.now() - (state.range - 1) * 86400000),
                    end: epochToDateTimeString(Date.now() + 86400000)
                },
                percents: {
                    start: 0,
                    end: 100,
                }
            };

        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setCacheKey,
    setRange,
    setInterval,
    setTotal,
    setQuery,
    setSession,
    setDates,
    addBreadcrumb,
    removeBreadcrumb,
    sliceBreadcrumb,
    resetBreadcrumbs,
    setZoomedRange,
    resetZoomIndexes
} = explorationSlice.actions;

export default explorationSlice.reducer;

export function setURLSearchParams(key, value) {
    let params = new URLSearchParams(window.location.search);
    params.set(key, value);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
}

export function removeURLSearchParams(key) {
    let params = new URLSearchParams(window.location.search);
    params.delete(key);
    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
}

function epochToDateTimeString(timestamp) {
    const date = new Date(timestamp);
    // set hours mminutes and seconds to 0
    return date.toISOString().substring(0, 10) + " 00:00";
}
