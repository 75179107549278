import React, {useEffect, useState} from 'react';
import {Divider, Stack, Typography} from "@mui/material";
import {firestore, functions} from "../../../../config";
import {useSelector} from "react-redux";
import Article from "./article";

const createIncrementTask = functions.httpsCallable("createIncrementTask");

const ArticlesList = () => {
    const {total} = useSelector((state) => state.exploration);
    const {cacheKey, dates} = useSelector((state) => state.exploration);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true)

    // useEffect(() => {
    //     console.log('articles', dates);
    //
    //     const initializeData = () => {
    //         setData(null);
    //     };
    //
    //     const getPageString = (dates) => {
    //         return `${dates.start.replaceAll("-", "d").replaceAll(" ", "T")}X${dates.end.replaceAll("-", "d").replaceAll(" ", "T")}`;
    //     };
    //
    //     const subscribeToDocument = (docPath, RTDBpath) => {
    //         const docRef = firestore.doc(docPath);
    //         console.log(docPath);
    //         return docRef.onSnapshot((doc) => {
    //             setData(null);
    //             if (doc.exists) {
    //                 const data = doc.data();
    //                 setData(data);
    //             } else {
    //                 console.log('ArticlesList does not exist', docPath);
    //             }
    //             createIncrementTask(RTDBpath);
    //         });
    //     };
    //
    //     const cleanup = (unsubscribe, RTDBpath) => {
    //         createDecrementTask({key: RTDBpath, value: 1})
    //             .then(() => null)
    //             .catch((e) => console.error(e));
    //         if (unsubscribe) {
    //             unsubscribe();
    //         }
    //     };
    //
    //     initializeData();
    //
    //     if (cacheKey && dates) {
    //         const page = getPageString(dates);
    //         const RTDBpath = `cache/${cacheKey}-articles-${page}`;
    //         const docPath = `cache/${cacheKey}/vizmode/articles/page/${page}`;
    //
    //         // Cleanup previous listener if exists
    //         if (unsubscribeRef.current) {
    //             cleanup(unsubscribeRef.current, RTDBpath);
    //         }
    //
    //         // Subscribe to the new document
    //         const unsubscribe = subscribeToDocument(docPath, RTDBpath);
    //         unsubscribeRef.current = unsubscribe;
    //
    //         // Cleanup function to detach listener and perform async cleanup
    //         return () => {
    //             if (unsubscribeRef.current) {
    //                 cleanup(unsubscribeRef.current, RTDBpath);
    //                 unsubscribeRef.current = null;
    //             }
    //         };
    //     }
    //
    //     // Cleanup in case cacheKey or dates become falsy
    //     return () => {
    //         if (unsubscribeRef.current) {
    //             cleanup(unsubscribeRef.current, `cache/${cacheKey}-articles-${getPageString(dates)}`);
    //             unsubscribeRef.current = null;
    //         }
    //     };
    // }, [cacheKey, dates]);

    useEffect(() => {
        setData(null);
        setLoading(true)

        const getPageString = (dates) => {
            return `${dates.start.replaceAll("-", "d").replaceAll(" ", "T")}X${dates.end.replaceAll("-", "d").replaceAll(" ", "T")}`;
        };

        // Cleanup in case cacheKey or dates become falsy
        if (cacheKey && dates) {
            console.log(dates)
            const page = getPageString(dates);
            const RTDBpath = `cache/${cacheKey}-articles-${page}`;
            const docPath = `cache/${cacheKey}/vizmode/articles/page/${page}`;

            const docRef = firestore.doc(docPath);

            return docRef.onSnapshot((doc) => {
                setData(null);
                if (doc.exists) {
                    const data = doc.data();
                    setData(data);
                    setLoading(false)
                } else {
                    console.log('Waiting for document to be created:', docPath);
                }
                createIncrementTask(RTDBpath)
            }, (error) => {
                console.error('Error listening to document:', error);
            });
        }
        return () => {
            console.log("Articles List cleanup")
        }
    }, [cacheKey, dates]);

    return (
        <Stack sx={{backgroundColor: "#fff"}} flex={1} maxWidth={720} spacing={1} overflow={"auto"}>
            {total === null ?
                "Loading recent articles"
                : total === 0 ?
                    "No articles found"
                    : loading ?
                        "Loading recent articles"
                        :
                        <Stack width={"100%"} px={4} py={3} spacing={4} boxSizing={"border-box"}>
                            {data.articles.map((article, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {index !== 0 && <Divider/>}
                                        <Article article={article}/>
                                    </React.Fragment>)
                            })}
                        </Stack>
            }
        </Stack>
    );
};

export default ArticlesList;