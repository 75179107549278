import React, {useState} from 'react';
import {Button, Link, Menu, Stack, Typography} from "@mui/material";
import {useDispatch} from "react-redux";
import {addBreadcrumb} from "../../explorationSlice";

const ArticleContent = ({string}) => {
    const content = JSON.parse(string)
    const [showContent, setShowContent] = useState(false)

    return (
        <>
            {content.map((e, i) => render_element(e, i, showContent))}
            <Typography>
                <Typography
                    onClick={() => setShowContent(!showContent)}
                    component={"span"}
                    sx={{
                        cursor: "pointer",
                        color: "blue"
                    }}>Show {showContent ? "less" : "more"}</Typography>
            </Typography>
        </>
    );
};

function render_element(e, index, showContent) {
    if (index === 0 || (showContent && index > 0)) {
        const [tag, data] = e;

        switch (tag) {
            case 'p':
                return <Paragraph key={index} data={render_text(data)}/>;
            case 'h1':
            case 'h2':
            case 'h3':
            case 'h4':
            case 'h5':
            case 'h6':
                return <Header key={index} data={render_text(data)}/>;
            case 'img':
                return <Image key={index} data={data}/>;
            case 'iframe':
                return <Iframe key={index} data={data}/>;
            default:
                return <Typography key={index} variant={tag}>{render_text(data)}</Typography>;
        }
    }
}

function render_text(data) {
    return data.map((e, index) => {
        const [text, emotion] = e;

        const response = text.map((t, i) => {
            if (typeof t === 'string') {
                return <React.Fragment key={i}>{t}</React.Fragment>
            } else {
                return <Entity key={i} data={t}/>
            }
        })

        if (emotion) {
            console.log(emotion)
            return (
                <span
                    key={index}
                    style={{
                        backgroundColor: emotion < 15 ? "LightGreen" : "#FFDDDD"
                    }}
                >
                    {response}
                </span>
            )
        } else {
            return <React.Fragment key={index}>{response}</React.Fragment>
        }
    })
}

const Entity = ({data}) => {
    const dispatch = useDispatch();
    const [surface_form, entity] = data;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAddBreadcrumb = () => {
        setAnchorEl(null);
        dispatch(addBreadcrumb({
            type: "entity",
            value: entity
        }))
    }

    const styles = {
        link: {
            color: "blue",
            cursor: "pointer",
            '&:hover': {
                background: "#fefefe"
            }
        }
    }

    return (
        <React.Fragment>
            <Link onClick={handleClick} sx={styles.link} underline={"none"}>
                {surface_form}
            </Link>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Stack p={2} py={1} spacing={1}>
                    <Stack>{entity}</Stack>
                    <Button onClick={handleAddBreadcrumb}>Add to breadcrumbs</Button>
                </Stack>
            </Menu>
        </React.Fragment>
    );
}


const Paragraph = ({data}) => <Typography>{data}</Typography>;

const Header = ({data}) => <Typography variant="h6" fontWeight={"bold"}>{data}</Typography>;

const Image = ({data}) => <img src={data} alt="" style={{"maxHeight": 360, "maxWidth": "100%"}}/>;

const Iframe = ({data}) => <iframe src={data} width="100%" height="315" frameBorder="0" allowFullScreen/>;


export default ArticleContent;