import React from "react";
import {Navigate, useRoutes} from "react-router-dom";
import ExplorationWrapper from "../pages/exploration/exploration_wrapper";


export function Routes() {
    return useRoutes([
        {
            key: "world-news",
            path: "/",
            element: <ExplorationWrapper/>,
            children: []
        },
        // {
        //     key: "FixSources",
        //     path: "/FixSources",
        //     element: <FixSources/>,
        //     children: []
        // },
        {key: "Redirect to ", path: "/", element: <Navigate to="/explore"/>},
    ]);
}

export const links = {
    primary: [
        // {
        //     label: "Alerts",
        //     to: "/alerts",
        // },
        // {
        //     label: "Reports",
        //     to: "/reports",
        // },
    ],
    secondary: [
        {
            label: "Explore News",
            to: "/",
        },
        // {
        //     label: "FixSources",
        //     to: "/FixSources",
        // },
    ],
};
