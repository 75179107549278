import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactECharts from "echarts-for-react";
import {useDispatch, useSelector} from "react-redux";
import {firestore, functions} from "../../../../../config";
import {setDates, setTotal} from "../../../explorationSlice";
import {Stack} from "@mui/material";
import {useSearchParams} from "react-router-dom";

const createIncrementTask = functions.httpsCallable("createIncrementTask");
const createDecrementTask = functions.httpsCallable("createDecrementTask");

const Chart = () => {
    const dispatch = useDispatch();
    const {cacheKey, dates} = useSelector((state) => state.exploration);
    const [isLoading, setIsLoading] = useState(true)

    const [searchParams, setSearchParams] = useSearchParams();
    const unsubscribeRef = useRef(null);
    const searchParamsRef = useRef(searchParams);
    const datesRef = useRef(dates);
    const [chartRef, setChartRef] = useState(null)

    let range = Number(searchParams.get('r'));
    let interval = searchParams.get('i');
    let length = (interval === 'daily' ? range : 24 * range) - 1;

    let initialOptions = {
        grid: {top: 24, right: 24, left: 52},
        animation: false,
        // animationDuration: 100,
        xAxis: {
            type: 'category',
            data: [],
            axisTick: {
                alignWithLabel: true
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: function (value) {
                    // Convert the value to K format
                    return value > 1000 ? (value / 1000) + 'K' : value;
                }
            }
        },
        dataZoom: [
            {
                type: 'slider',
                start: 0,
                end: 100,
                minValueSpan: 1
            }],
        series: [
            {
                data: [],
                name: 'Total',
                type: 'line',
                color: 'blue',
                symbol: 'none',
                areaStyle: {},
            },
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    formatter: function (params) {
                        // Format the value to K format
                        const value = params.value;
                        if (typeof value === 'number') {
                            // value is a number
                            if (value < 1000) return Math.floor(value).toString()
                            if (value < 10000) return (value / 1000).toFixed(1) + 'K'
                            return Math.floor(value / 1000) + 'K'
                        }
                        return value
                    }
                }
            },
        },
    };

    const [chartOptions, setChartOptions] = useState(initialOptions)

    function updateChartOptions(data) {
        if (chartRef) {
            const options = chartRef.getEchartsInstance().getOption();
            const {series, title} = data

            if (series) {
                let [start, end] = searchParamsRef.current.get('z').split('-').map(e => Number(e));

                setChartOptions({
                    ...options,
                    dataZoom: [
                        {
                            start: start / length * 100,
                            end: end / length * 100,
                        }],
                    xAxis: {data: series.published},
                    series: [{data: series.count}]
                });
            }
        }
    }

    useEffect(() => {
        // Update the ref whenever searchParams changes
        searchParamsRef.current = searchParams;
    }, [searchParams]);

    useEffect(() => {
        // Update the ref whenever searchParams changes
        datesRef.current = dates;
    }, [dates]);


    // useEffect(() => {
    //     setIsLoading(true);
    //
    //     if (cacheKey) {
    //         const range = Number(searchParams.get('r'));
    //         const interval = searchParams.get('i');
    //         const RTDBpath = `cache/${cacheKey}-frequency-${range}-${interval}`;
    //         const docPath = `cache/${cacheKey}/vizmode/frequency/range/${range}/interval/${interval}`;
    //
    //         // Subscribe to the document
    //         const docRef = firestore.doc(docPath);
    //         const unsubscribe = docRef.onSnapshot((doc) => {
    //             createIncrementTask(RTDBpath)
    //             if (doc.exists) {
    //                 const [start, end] = searchParamsRef.current.get('z').split('-').map(e => Number(e));
    //                 // Only update state if the component is still mounted
    //                 const data = doc.data();
    //                 updateChartOptions(data.series);
    //                 console.log('dates', dates)
    //
    //                 dispatch(setTotal(data.total));
    //
    //                 if (!datesRef.current) {
    //                     // The dates are not set yet
    //                     const newDates = {
    //                         start: data.series.published[start],
    //                         end: data.series.published[end]
    //                     };
    //
    //                     dispatch(setDates(newDates));
    //                 } else {
    //                     const newDates = {
    //                         start: data.series.published[start],
    //                         end: data.series.published[end]
    //                     };
    //
    //                     if (newDates.start !== datesRef.current.start || newDates.end !== datesRef.current.end) {
    //                         // The new dates are different from the old ones
    //                         dispatch(setDates(newDates));
    //                     }
    //                 }
    //
    //                 setIsLoading(false);
    //             } else {
    //                 console.log('Document does not exist');
    //             }
    //         });
    //
    //         return () => {
    //             console.log("chart cleanup")
    //             createDecrementTask({key: RTDBpath, value: 1}).then(r => null).catch(e => console.error(e));
    //             unsubscribe();
    //         };
    //     }
    // }, [cacheKey, range, interval]);

    useEffect(() => {
        setIsLoading(true);

        if (cacheKey) {
            const range = Number(searchParams.get('r'));
            const interval = searchParams.get('i');
            const RTDBpath = `cache/${cacheKey}-frequency-${range}-${interval}`;
            const docPath = `cache/${cacheKey}/vizmode/frequency/range/${range}/interval/${interval}`;

            // Cleanup previous listener if exists
            if (unsubscribeRef.current) {
                console.log("chart cleanup");
                createDecrementTask({key: RTDBpath, value: 1}).then(() => null).catch(e => console.error(e));
                unsubscribeRef.current();
            }

            // Subscribe to the document
            const docRef = firestore.doc(docPath);
            const unsubscribe = docRef.onSnapshot((doc) => {
                createIncrementTask(RTDBpath);
                if (doc.exists) {
                    const [start, end] = searchParamsRef.current.get('z').split('-').map(Number);

                    const data = doc.data();
                    const {series, total} = data;

                    if (!series) {
                        console.log('No series data');
                        dispatch(setTotal(0));
                        updateChartOptions({series: null});
                        setIsLoading(false);
                        return;
                    }

                    updateChartOptions({series});

                    dispatch(setTotal(total));

                    const newDates = {
                        start: series.published[start],
                        end: series.published[end]
                    };

                    if (!datesRef.current || (newDates.start !== datesRef.current.start || newDates.end !== datesRef.current.end)) {
                        datesRef.current = newDates;
                        dispatch(setDates(newDates));
                    }

                    setIsLoading(false);
                } else {
                    console.log('Document does not exist');
                }
            });

            // Save the current unsubscribe function
            unsubscribeRef.current = unsubscribe;

            return () => {
                if (unsubscribeRef.current) {
                    console.log("chart cleanup");
                    createDecrementTask({key: RTDBpath, value: 1}).then(() => null).catch(e => console.error(e));
                    unsubscribeRef.current();
                    unsubscribeRef.current = null;
                }
            };
        }
    }, [cacheKey, range, interval]);

    let timer;

    function onDataZoom(instance) {
        clearTimeout(timer);
        const option = instance.getOption();
        const dataZoom = option.dataZoom[0];

        const indexes = [dataZoom.startValue, dataZoom.endValue];

        timer = setTimeout(async () => {
            dispatch(setDates({
                start: option.xAxis[0].data[indexes[0]],
                end: option.xAxis[0].data[indexes[1]]
            }));
            console.log('searchParams', searchParamsRef.current.toString())
            searchParamsRef.current.set('z', indexes.join('-'));
            setSearchParams(searchParamsRef.current);
        }, 333);
    }

    return (
        <Stack flex={1}>
            <ReactECharts
                option={chartOptions}
                showLoading={isLoading}
                ref={(e) => {
                    e && setChartRef(e);
                }}
                onChartReady={(e) => {
                    e.on('dataZoom', () => onDataZoom(e));
                }}
            />
        </Stack>)
};

export default Chart;