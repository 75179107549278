import {createTheme} from "@mui/material";


const palette = {
    primary: {main: "#0000CD"},
}

const typography = {
    fontFamily: '"Noto Sans", sans-serif',
}

const data_grid = {
    MuiDataGrid: {
        root: {
            border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#fff', '& .MuiDataGrid-row:hover': {
                cursor: 'pointer', // Change cursor on hover
            },
        }, header: {
            border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#f5f5f5',
        }, cell: {
            border: '1px solid #e0e0e0', borderRadius: '4px', backgroundColor: '#fff',
        }, row: {
            '&:hover': {
                backgroundColor: '#f5f5f5',
            },
        },
    },
}

export const theme = createTheme({
    palette, typography, components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    '& .MuiDataGrid-row:hover': {
                        cursor: 'pointer', // Change cursor on hover
                    },
                },
            },
        },
    },
});