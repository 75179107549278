import React, {useEffect, useState} from 'react';
import {Box, Divider, Link, Stack, Typography} from "@mui/material";
import ArticleContent from "./article_content";

const Article = ({article}) => {
    const {title, content, published, source, url} = article;

    const [faviconUrl, setFaviconUrl] = useState(null);


    return (
        <Stack spacing={2}>
            <Typography sx={{"textWrap": "balance", "wordBreak": "break-word"}}
                        variant="h4"
                        fontSize={"1.5rem"}
                        flex={1}
                        fontWeight={"bold"}>
                {title}
            </Typography>
            {/*<img src={article.image} alt="" width={"120px"}/>*/}
            <Stack justifyContent={"space-between"} direction={"row"}>
                <Stack direction={"row"}>
                    {faviconUrl &&
                        <Box
                            component="img"
                            sx={{
                                maxWidth: 16,
                                maxHeight: 16,
                                padding: "4px"
                            }}
                            src={"https://" + source + "/favicon.ico"}
                        />
                    }
                    <Link href={url} underline="hover" target={"_blank"}
                          color={"blue"}>{source}</Link>
                </Stack>
                <Typography color="#64748b">{published}</Typography>
            </Stack>
            <ArticleContent string={content}/>
        </Stack>
    )
};

export default Article;