import React, {useEffect} from 'react';
import FrequencyChart from "./components/frequency_chart/frequency_chart";
import {functions} from "../../config";
import {useDispatch, useSelector} from "react-redux";
import {setCacheKey, setTotal} from "./explorationSlice";
import {Divider, Stack} from "@mui/material";
import Breadcrumbs from "./components/breadcrumbs/breadcrumbs";
import ArticlesList from "./components/articles_list/articles_list";
import SearchInput from "./components/search_input/search_input";
import {useSearchParams} from "react-router-dom";
import Tables from "./components/data_table/tables";


const getCacheKey = functions.httpsCallable("getCacheKey");

const Exploration = () => {
    const dispatch = useDispatch();
    let [searchParams,] = useSearchParams();

    let filters = searchParams.get("f")
    let query = searchParams.get("q")
    let breadcrumbs = searchParams.get("b")
    let keywords = searchParams.get("k")

    useEffect(() => {
        filters = JSON.parse(searchParams.get("f"))
        query = JSON.parse(searchParams.get("q"))
        breadcrumbs = JSON.parse(searchParams.get("b"))
        keywords = searchParams.get("k")

        dispatch(setTotal(null))

        getCacheKey({filters, query, breadcrumbs, keywords}).then(res => {
            console.log(res.data)
            dispatch(setCacheKey(res.data));
        });
        return () => {
            dispatch(setCacheKey(null));
        }
    }, [filters, query, breadcrumbs, keywords]);

    return (
        <Stack flex={1} width={"calc(100% - 200px)"}>
            <Breadcrumbs/>
            <Divider color={"silver"}/>
            <Stack direction={"row"} flex={1} sx={{"overflow-y": "auto"}}>
                <Stack spacing={4} p={2} sx={{"overflow-x": "clip", "overflow-y": "auto"}} flex={1}>
                    <Stack spacing={3}>
                        <SearchInput/>
                    </Stack>
                    <Stack spacing={4}>
                        <FrequencyChart/>
                        <Tables/>
                    </Stack>
                </Stack>
                <Divider color={"silver"} orientation={'vertical'}/>
                <ArticlesList/>
            </Stack>
        </Stack>
    );
};

export default Exploration;


