import React, {useState} from 'react';
import {Menu, MenuItem} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setTotal} from "../../../explorationSlice";

const RangeSelect = () => {
    const dispatch = useDispatch();
    const [intervalAnchor, setIntervalAnchor] = useState(null)
    const intervalOpen = Boolean(intervalAnchor);
    let [searchParams, setSearchParams] = useSearchParams();
    let range = Number(searchParams.get('r'));
    let interval = searchParams.get('i');

    return (
        <>
            <span onClick={handleRangeMenu}
                  style={{color: "#1B4BDC", borderBottom: "1px dashed blue",}}
            >last {range} days</span>.
            <Menu
                id="range-menu"
                anchorEl={intervalAnchor}
                open={intervalOpen}
                onClose={handleRangeClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleRangeChange} value={7} selected={range === 7}>Last 7 days</MenuItem>
                <MenuItem onClick={handleRangeChange} value={30} selected={range === 30}>Last 30 days</MenuItem>
                <MenuItem disabled onClick={handleRangeChange} value={180} selected={range === 180}>Last 180
                    days</MenuItem>
            </Menu>
        </>
    );

    function handleRangeClose(e) {
        setIntervalAnchor(null);
    }

    function handleRangeMenu(e) {
        setIntervalAnchor(e.currentTarget);
    }

    function handleRangeChange(e) {
        const value = e.target.value;
        searchParams.set('r', value);
        const start = 0;
        const end = value * (interval === 'daily' ? 1 : 24) - 1;
        searchParams.set('z', `${start}-${end}`);
        setSearchParams(searchParams);
        dispatch(setTotal(null));
        setIntervalAnchor(null);
    }
};

export default RangeSelect;