import React, {useEffect, useRef} from 'react';
import {Typography} from "@mui/material";
import RangeSelect from "./range_select";
import IntervalSelect from "./interval_select";
import {useSelector} from "react-redux";

const Header = () => {
    const {total} = useSelector((state) => state.exploration);
    const totalRef = useRef(); // Create a ref to the total element

    // Use the useEffect hook to apply the highlight class whenever total changes
    useEffect(() => {
        if (!total || !totalRef.current) return;

        console.log(total);
        totalRef.current.classList.add('highlight');
        const timeoutId = setTimeout(() => totalRef.current.classList.remove('highlight'), 2000);

        return () => clearTimeout(timeoutId);
    }, [total]);

    const getMessage = () => {
        if (total === null) return "Getting  articles for the ";
        if (total === 0) return "No articles for the ";
        if (total > 0) return <><span ref={totalRef}>{total?.toLocaleString()}</span> articles processed in the </>;
    };


    return (
        <Typography variant="h5">
            <>
                {getMessage()}
                <RangeSelect/>
                <IntervalSelect/>
            </>
        </Typography>
    );
};

export default Header;