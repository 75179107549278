import Exploration from "./pages/exploration/exploration";
import {Stack, ThemeProvider} from "@mui/material";
import Navigation from "./components/navigation";
import {Routes} from "./utils/routes";
import {BrowserRouter} from "react-router-dom";
import {theme} from "./utils/theme";

function App() {
    return (
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <Stack direction={"row"} maxWidth={"100%"} height={"100vh"}>
                    {/*<Navigation/>*/}
                    <Routes/>
                </Stack>
            </ThemeProvider>
        </BrowserRouter>
    );
}

export default App;
