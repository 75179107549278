import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";

// Initialize Firebase
const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
});

// if (window.location.hostname === "localhost") {
//     app.functions().useEmulator("localhost", 5001);
//     app.auth().useEmulator("http://localhost:9099");
//     app.database().useEmulator("localhost", 9000);
//     app.firestore().useEmulator("localhost", 8084);
//     app.storage().useEmulator("localhost", 9199);
// }

export const firestore = firebase.firestore();
export const functions = firebase.functions();
export const storage = firebase.storage();
export const database = firebase.database();
export const auth = firebase.auth();


export const incrementDatabase = firebase.database.ServerValue.increment(1);
export const decrementDatabase = firebase.database.ServerValue.increment(-1);
export const RDBTimestamp = firebase.database.ServerValue.TIMESTAMP;

export const firestoreTimestamp =
    firebase.firestore.FieldValue.serverTimestamp();
export const increment = firebase.firestore.FieldValue.increment(1);
export const fieldValue = firebase.firestore.FieldValue;
