import React, {useState} from 'react';
import {Menu, MenuItem} from "@mui/material";
import {useSearchParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setTotal} from "../../../explorationSlice";

const IntervalSelect = () => {
    const dispatch = useDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    let interval = searchParams.get('i');

    const [intervalAnchor, setIntervalAnchor] = useState(null)
    const intervalOpen = Boolean(intervalAnchor);

    return (
        <>
            {" Articles grouped by published date "}
            <span onClick={handleIntervalMenu}
                  style={{color: "#1B4BDC", borderBottom: "1px dashed blue",}}
            >{interval}</span>.
            <Menu
                id="interval-menu"
                anchorEl={intervalAnchor}
                open={intervalOpen}
                onClose={handleIntervalClose}
            >
                <MenuItem onClick={() => handleIntervalChange('hourly')}
                          selected={interval === "hourly"}>hourly</MenuItem>
                <MenuItem onClick={() => handleIntervalChange('daily')}
                          selected={interval === "daily"}>daily</MenuItem>
            </Menu>
        </>
    );

    function handleIntervalClose(e) {
        setIntervalAnchor(null);
    }

    function handleIntervalMenu(e) {
        setIntervalAnchor(e.currentTarget);
    }

    function handleIntervalChange(value) {
        searchParams.set('i', value);
        const indexes = searchParams.get('z').split('-').map(e => Number(e));
        let start = value === 'daily' ? Math.floor(indexes[0] / 24) : indexes[0] * 24;
        let end = value === 'daily' ? Math.floor(indexes[1] / 24) : (indexes[1] + 1) * 24 - 1;
        if (start === end) {
            if (start === 0) {
                end += 1;
            } else {
                start -= 1;
            }
        }
        searchParams.set('z', `${start}-${end}`);
        setSearchParams(searchParams);
        dispatch(setTotal(null));
        setIntervalAnchor(null);
    }
};

export default IntervalSelect;