import React, {useEffect, useState, useContext} from "react";
import {auth} from "../config";

export const UserContext = React.createContext();

export const UserProvider = (props) => {
	const [session, setSession] = useState({loading: true, user: null, claims: null});

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			setSession({loading: false, user});
		});
		return () => unsubscribe();
	}, []);


	return (
		<UserContext.Provider value={session}>
			{!session.loading && props.children}
		</UserContext.Provider>
	);
};

export const useSession = () => {
	return useContext(UserContext);
};
