import React, {useState} from 'react';
import {Box, Chip, ListItem, Stack, Typography} from "@mui/material";
import DataTable from "./data_table";
import {useSelector} from "react-redux";

const Tables = () => {
    const {total} = useSelector((state) => state.exploration);
    const [active, setActive] = useState({label: 'Continents', name: 'continent', type: null})

    const tabs = [
        {label: 'Continents', name: 'continent', type: null},
        {label: 'Countries', name: 'country', type: null},
        {label: 'Websites', name: 'source', type: null},
        {label: 'Languages', name: 'language', type: null},
        {label: 'Categories', name: 'category', type: null},
        {label: 'Persons', name: 'class_Person', type: 'entity'},
        {label: 'Organisations', name: 'class_Organisation', type: 'entity'},
        {label: 'Places', name: 'class_Place', type: 'entity'}
    ]

    return (
        <React.Fragment>
            <Stack direction={'row'} spacing={1} flexWrap={'wrap'}>
                {tabs.map((tab) => {
                    return <Box key={tab.name} label={tab.label} onClick={() => setActive(tab)}>{tab.label}</Box>
                })}
            </Stack>
            <Typography fontWeight={"bold"}>{active.label}</Typography>

            {total ?
                <DataTable name={active.name} type={active.type}/>
                : null
            }
            {/*<Typography fontWeight={"bold"}>Countries</Typography>*/}
            {/*<DataTable name={'country'}/>*/}
            {/*<Typography fontWeight={"bold"}>Websites</Typography>*/}
            {/*<DataTable name={'source'}/>*/}
            {/*<Typography fontWeight={"bold"}>Article Languages</Typography>*/}
            {/*<DataTable name={'language'}/>*/}
            {/*<Typography fontWeight={"bold"}>Categories</Typography>*/}
            {/*<DataTable name={'category'}/>*/}
            {/*<Typography fontWeight={"bold"}>Mentioned Persons</Typography>*/}
            {/*<DataTable name={'class_Person'} type={"entity"}/>*/}
            {/*<Typography fontWeight={"bold"}>Mentioned Organisations</Typography>*/}
            {/*<DataTable name={'class_Organisation'} type={"entity"}/>*/}
            {/*<Typography fontWeight={"bold"}>Mentioned Places</Typography>*/}
            {/*<DataTable name={'class_Place'} type={"entity"}/>*/}
        </React.Fragment>
    );
};

export default Tables;