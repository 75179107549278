import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {resetZoomIndexes} from "../../explorationSlice";
import {Chip, Stack} from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ClearIcon from '@mui/icons-material/Clear';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import {getIcon} from "./utils";
import {useSearchParams} from "react-router-dom";

const Breadcrumbs = () => {
    const dispatch = useDispatch();
    const {dates} = useSelector((state) => state.exploration);
    let [searchParams, setSearchParams] = useSearchParams();
    const range = Number(searchParams.get('r'))
    const interval = searchParams.get('i')
    const breadcrumbs = JSON.parse(searchParams.get('b'))
    const indexes = searchParams.get('z').split('-').map(e => Number(e))


    return (
        <Stack flex={0} p={1} direction={"row"} spacing={0} alignItems={"center"}>
            <Chip icon={<PublicIcon/>} label="World News" variant={"outlined"} onClick={handleReset}/>

            {breadcrumbs && breadcrumbs.map((b, index) => {
                return (
                    <React.Fragment key={index}>
                        <KeyboardArrowRightIcon/>
                        <Chip
                            icon={getIcon(b.type)}
                            label={b.value}
                            variant={"outlined"}
                            onDelete={() => handleRemove(b)}
                            deleteIcon={<ClearIcon fontSize={"small"}/>}
                            onClick={() => handleSlice(b)}
                        />
                    </React.Fragment>
                )
            })}

            {dates && isZoomedRange(indexes, interval, range) ?
                <Stack direction={"row"} alignItems={"center"}>
                    <KeyboardArrowRightIcon/>
                    <Chip icon={<ZoomInIcon/>} label={dates.start + " - " + dates.end}
                          color={"primary"}
                          onDelete={() => handleResetZoomIndexes()}
                          deleteIcon={<ClearIcon fontSize={"small"}/>}
                    />
                </Stack>
                : null}
        </Stack>)

    function handleRemove(breadcrumb) {
        const {type, value} = breadcrumb;
        const breadcrumbs = JSON.parse(searchParams.get('b'))
        const index = breadcrumbs.findIndex(e => e.type === type && e.value === value);
        const new_breadcrumbs = breadcrumbs?.filter((e, i) => i !== index)
        if (new_breadcrumbs.length === 0) {
            searchParams.delete("b")
        } else {
            searchParams.set("b", JSON.stringify(new_breadcrumbs))
        }
        setSearchParams(searchParams)
    }

    function handleSlice(breadcrumb) {
        const {type, value} = breadcrumb;
        const breadcrumbs = JSON.parse(searchParams.get('b'))
        const index = breadcrumbs.findIndex(e => e.type === type && e.value === value);
        const new_breadcrumbs = breadcrumbs?.slice(0, index + 1);
        if (new_breadcrumbs.length === 0) {
            searchParams.delete("b")
        } else {
            searchParams.set("b", JSON.stringify(new_breadcrumbs))
        }
        setSearchParams(searchParams)
    }

    function handleReset() {
        searchParams.delete("b")
        setSearchParams(searchParams)
    }

    function handleResetZoomIndexes() {
        searchParams.set("z", `${0}-${(interval === 'daily' ? range : 24 * range) - 1}`)
        setSearchParams(searchParams)
    }
}

//     {addBreadcrumb: (state, action) => {
//         console.log("dispatch")
//         const crumb = action.payload;
//         state.breadcrumbs = state.breadcrumbs?.concat(crumb);
//     },
//         removeBreadcrumb: (state, action) => {
//         console.log("dispatch")
//         const {type, value} = action.payload;
//         const index = state.breadcrumbs.findIndex(e => e.type === type && e.value === value);
//         state.breadcrumbs = state.breadcrumbs?.filter((e, i) => i !== index);
//     },
//         sliceBreadcrumb: (state, action) => {
//         console.log("dispatch")
//         const {type, value} = action.payload;
//         const index = state.breadcrumbs.findIndex(e => e.type === type && e.value === value);
//         state.breadcrumbs = state.breadcrumbs?.slice(0, index + 1);
//     },
//         resetBreadcrumbs: (state, action) => {
//         console.log("dispatch")
//         state.breadcrumbs = [];
//     },
// };

export default Breadcrumbs;

function isZoomedRange(indexes, interval, range) {
    const [start, end] = indexes
    return start > 0 || end < (interval === "daily" ? range : range * 24) - 1;
}

