import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import Table from "./table";


const DataTable = ({name, type}) => {
    const {cacheKey} = useSelector((state) => state.exploration);
    const [path, setPath] = useState(null);

    let [searchParams, setSearchParams] = useSearchParams();
    const range = Number(searchParams.get('r'));
    const interval = searchParams.get('i');

    useEffect(() => {
        setPath(null)
        if (cacheKey) {
            const RTDBpath = `cache/${cacheKey}-${name}-${range}-${interval}`;
            const docPath = `cache/${cacheKey}/vizmode/${name}/range/${range}/interval/${interval}`;
            setPath({RTDBpath, docPath})
        }
    }, [cacheKey, range, interval, name]);

    return path && <Table props={{path, name, type}}/>
};

export default DataTable;